<template>
  <div>
    <div class="addPromotionbox">
      <div class="titlebox">
        <span class="marginleft10" @click="toPromotionManagement"
          ><i class="el-icon-arrow-left"></i>促销管理</span
        >
        <span> - 新建促销</span>
      </div>
      <div v-for="item in stencilList" :key="item.id">
        <div class="mainbox">
          <div class="inlinebolockbox">
            <img class="imgbox" :src="item.iconUrl" alt="" />
          </div>
          <div class="inlinebolockbox marginleft55 width800">
            <div class="namebox">
              {{ item.name }}
            </div>
            <div class="margintop20 labelone">
              <div class="inlinebolockbox width200">
                用户范围：{{ item.memberRange }}
              </div>
              <div class="inlinebolockbox">用户群：{{ item.member }}</div>
            </div>
            <div class="margintop15 labelone">
              <div class="inlinebolockbox width200">
                折扣方式：{{ item.promotionMethod | formatPromotionMethod }}
                <span class="marginleft10" v-if="item.promotionMethod == 2"
                  >{{ item.costValue }}折</span
                >
                <span class="marginleft10" v-if="item.promotionMethod == 1"
                  >{{ item.costValue }}元</span
                >
              </div>
              <div class="inlinebolockbox" v-if="item.member != 0">
                促销库存：{{ item.member }}
              </div>
              <div class="inlinebolockbox" v-if="item.member1 == 0">
                促销库存：无上限
              </div>
            </div>
            <div class="margintop20 labeltwo">
              预定时间：{{ item.bookDateStart }}-{{ item.bookDateEnd }},{{
                item.bookWeekdays
              }}
              <span class="marginleft20">
                入住时间：{{ item.start }}-{{ item.end }},{{ item.weekdays }}
              </span>
            </div>
          </div>
          <div class="inlinebolockbox">
            <div style="line-height: 114px">
              <el-button type="primary" size="mini" @click="toAdd(item)"
                >马上创建</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPromotionTemplateList_api } from "../../apis/hotelPackage";
export default {
  data() {
    return {
      stencilList: [],
      searchData: {
        pageNumber: 1,
        pageSize: 15,
        keyword: "",
      },
    };
  },
  created() {
    this.getStencilList();
  },
  filters: {
    formatPromotionMethod(val) {
      if (val) {
        if (val == 1) {
          return "立减";
        } else if (val == 2) {
          return "打折";
        }
      }
    },
  },
  methods: {
    async getStencilList() {
      let { code, data } = await getPromotionTemplateList_api(this.searchData);
      if (code == 0) {
        this.stencilList = data.rows;
      }
    },
    //回到促销管理
    toPromotionManagement() {
      this.$router.push({
        path: "/promotionManagementYiLong",
      });
    },
    toAdd(item) {
      this.$router.push({
        path: "/addAdvanceReservePromote",
        query: {
          templateId: item.id,
          promotionName: item.name,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.addPromotionbox {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
  border-radius: 10px;
  padding-bottom: 20px;
}
.titlebox {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: #256def;
  border-radius: 10px 10px 0px 0px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fafcff;
}
.marginleft10 {
  margin-left: 10px;
  cursor: pointer;
}
.mainbox {
  margin-top: 30px;
  margin-left: 20px;
  width: 1140px;
  border-bottom: 1px solid #c9c9c9;
}
.inlinebolockbox {
  display: inline-block;
  vertical-align: top;
}
.imgbox {
  width: 190px;
  height: 130px;
}
.marginleft55 {
  margin-left: 55px;
}
.namebox {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
}
.margintop20 {
  margin-top: 20px;
}
.labelone {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.width200 {
  width: 200px;
}
.margintop15 {
  margin-top: 15px;
}
.marginleft10 {
  margin-left: 10px;
}
.labeltwo {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.marginleft20 {
  margin-left: 20px;
}
.width800 {
  width: 810px;
}
.zhanwei1 {
  width: 100%;
  height: 100px;
}
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}
</style>

